import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
   
    is_loading:false,
    number_of_files:null,
    upload_failed_files:[],
    open_dropzone_popup: false,
    upload_list_drawer: false,
    selected_files: [],
    in_progress_files: [],
    progress: [],
    upload_status: [],
    abort_controller: [],
    file_progress: 0.0,
    total_progress: 0.0,
    abort_all: false,
    uploading_file_index:null,
    browser_alert_popup:false,
    logout_clicked:false
};

export const uploadSlice = createSlice({
  name: "uploads",
  initialState: initialValue,
  reducers: {
    setUploadState: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetUploadState: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setUploadState, resetUploadState } = uploadSlice.actions;
export default uploadSlice.reducer;