import { Box, CircularProgress, Drawer, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import CustomTooltip from '../../../components/CustomTooltip';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ArticleIcon from '@mui/icons-material/Article';
import { resetUploadState, setUploadState } from '../../../redux/uploadSlice';
import CustomVideoLinearProgress from '../../../components/CustomVideoLinearProgress';
import { sizeConverter } from '../../../utils/commonServices';
import { abortUpload } from '../abortManager';
import { setAssetState } from '../../../redux/assetSlice';

const UploadDrawer = (props) => {
  const { uploadProps } = props;
  const uploadReducer = useSelector((state) => state.root.uploadReducer);
  // console.log(uploadProps, 'upload props drawer..........................')
  const [open, setOpen] = useState(false);
 // const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true);
  // console.log(uploadReducer.selected_files, 'reducer selected files =============================')
  const dispatch = useDispatch();
  const [fileIndex, setFileIndex] = useState(null)

  const handleClose = () => {
    if(uploadReducer.is_loading){
      dispatch(setUploadState({browser_alert_popup:true}))
    }else{
      handleAbort(uploadReducer?.uploading_file_index)
      uploadProps.setSelectedFiles([])
      dispatch(setUploadState({ upload_list_drawer: false, is_loading: false, abort_all: true ,uploading_file_index:null}))
      setTimeout(() => {
        dispatch(setUploadState({abort_all:false}))
      }, 50);
    }
   
    // setOpen(false);
   // setIsDrawerVisible(false);
  };

  const handleAbort = (fileIndex) => {
    abortUpload(fileIndex);
    // Optionally dispatch an action to remove the upload from Redux
  //  dispatch({ type: 'REMOVE_UPLOAD', payload: id });
  };
  // useEffect(()=> {
  //  // setIsDrawerVisible(true);
  //  console.log(uploadProps)
  //  console.log("files", uploadReducer.selected_files)
  //   uploadProps.uploadFiles()
  // }, [])

  // useEffect(() => {
  //   if (!uploadReducer.is_loading) {
  //     handleClose()
  //   }
  //   // if (uploadReducer.is_loading) {
  //   //   dispatch(setUploadState({ upload_list_drawer: true }))
  //   //   // setOpen(true)
  //   //   setIsDrawerVisible(true)
  //   // } else {
  //   //     handleClose()
  //   // }

  //   // return () => {

  //   // }

  // }, [uploadReducer.is_loading])




 
  useEffect(() => {

    const handleUnload = () => {
        dispatch(resetUploadState())
        localStorage.setItem("isReloading", true);
        dispatch(setUploadState({ upload_list_drawer: false, is_loading: false }))
    };

    window.addEventListener('beforeunload', alertUser)
    window.addEventListener("load", handleUnload);
    if(uploadReducer.logout_clicked){
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener("load", handleUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener("load", handleUnload);
    }
  }, [uploadReducer.is_loading, uploadReducer?.logout_clicked])



  const alertUser = e => {
    if (uploadProps.isLoading) {
      e.preventDefault()
      e.returnValue = 'Upload will be cancelled if you leave this page!!'
    }
  }




  const stopUpload = (abortIndex) => {
    const newAbortArray = uploadReducer.abort_controller?.map((item, i) =>{
      if(i == abortIndex) {
        return {...item, trigger: true}
      } else {
        return item
      }
    })
    dispatch(setUploadState({abort_controller: newAbortArray}))
  }

  return (
    <>
       <Drawer
        variant="permanent"
        anchor={"bottom"}
        open={uploadReducer.upload_list_drawer}
        onClose={handleClose}
        ModalProps={{
          keepMounted: true,
          hideBackdrop: true,
        }}
        transitionDuration={500}
        elevation={24}
        hideBackdrop={true}
        sx={{
          zIndex: 1500,
          boxShadow: '1px 4px 10px rgba(0, 0, 0, 0.2)',
          "& .MuiDrawer-paper": {
            height: isExpanded ? '400px' : '60px',
            transition: 'height 0.3s ease',
            left: { xs: "5%", sm: "40%", md: "52%", lg: "65%", xl: "70vw" },
            bottom: "0px",
            right: 20,
            borderRadius: "20px 20px 0 0",
            backgroundColor: "#ddd",
            overflow: "hidden",
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            backgroundColor: "#FFFFFF",
          }}
        >
          {/* Top box with info and icons */}
          <Box
            sx={{
              backgroundColor: "#52636C40",
              width: "100%",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: 'black',
              px: 2,
              cursor: "pointer",
              minHeight: '60px'
            }}
          >
            <Box className='flex a-center' sx={{ gap: 2 }}>
              <Typography>{uploadReducer.is_loading ? `Uploading...` : 'Upload Complete'}</Typography>
            </Box>
            <Box>
              {isExpanded ? (
                <IconButton onClick={() => setIsExpanded(false)}>
                  <KeyboardArrowDownIcon sx={{ color: "#000" }} />
                </IconButton>
              ) : (
                <IconButton onClick={() => setIsExpanded(true)}>
                  <KeyboardArrowUpIcon sx={{ color: "#000" }} />
                </IconButton>
              )}
              <IconButton onClick={handleClose}>
                <CloseOutlinedIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
          </Box>

          {/* Scrollable content box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: 'column',
              maxHeight: "calc(100% - 70px)",
              overflowY: "auto",
              maxWidth: '100%',
              width: '100%'
            }}
          >
            {uploadProps?.selectedFiles.length != 0 && uploadProps?.selectedFiles?.map((file, i) => (

<Box m={2} sx={{ borderBottom: 2, borderBottomColor: '#EBEBEB', pb: 2, }}>
<Box
  width="100%"
  style={{
    display: "flex",
    justifyContent: "space-between",
    // color: theme.palette.primary.light,
    fontStyle: "italic",
  }}
  mt={1}
  mb={1}
>
  <CustomTooltip title= {file.name}>
    <Typography
    component='span'
      sx={{
        color: '#08231B',
        fontSize: {xs:12, sm: 13},
        fontWeight: 600,
        fontFamily: 'Rubik',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "60%",
      }}
    >
      {file.name}
    </Typography>
  </CustomTooltip>
  <Typography sx={{color: '#08231B',
        fontSize:{xs: 12, sm: 13},
        fontFamily: 'Rubik',
        fontWeight: 600,}} >{sizeConverter(file.size)}</Typography>
</Box>
<CustomVideoLinearProgress
  // imageURL = {imgSrc.find((img)=> img.name == file.name)?.image}
  // progress={progress[index]}
  progress= {uploadReducer?.progress[i]}
   status={uploadReducer.upload_status[i]}
  stopUpload={() => handleAbort(i)}
  fontSize = {20}
  bar = {8}
  drawerUpload
/>
</Box>

              // <Box key={file?.name} sx={{
              //   width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between',
              //   // minHeight:'30px',
              //   //  height:'30px', 
              //   //  maxHeight:'30px',
              //   '&:hover': { backgroundColor: '#00000010' }, py: 3, px: 2,
              // }}>
              //   <Box className='flex row a-start j-start'  >
              //     <Box className='flex a-center j-center' sx={{ width: '30px' }}>
              //       <ImageIcon />
              //     </Box>
              //     <CustomTooltip title={file?.name}>
              //       <Box sx={{ width: '70%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'left', maxWidth: '220px', minWidth: '220px' }}>
              //         {file?.name}
              //       </Box>
              //     </CustomTooltip>
              //   </Box>


              //   <Box className='flex a-start j-start' sx={{ width: '100%', justifyContent: 'start' }}>
              //     {/* {uploadProps?.progress[i]} */}
              //     {uploadReducer?.progress[i] < 100 ?

              //       <CustomVideoLinearProgress
              //         // imageURL = {imgSrc.find((img)=> img.name == file.name)?.image}
              //         drawerUpload
              //         progress={uploadReducer?.progress[i]}
              //         // status={uploadStatus[index]}
              //         // stopUpload={() => stopUpload(index)}
              //         fontSize={20}
              //         bar={8}
              //       /> : <CheckCircleOutlineOutlinedIcon sx={{ color: 'green' }} />}
              //     {/* <CircularProgressWithLabel  variant="determinate" value={uploadProps?.progress[i]} /> 
              //         : <CheckCircleOutlineOutlinedIcon sx={{color:'green'}}/>     */}
              //   </Box>
              // </Box>

            ))}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};



export default UploadDrawer;
