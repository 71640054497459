import { Box, Card, CardContent, CardActions, Grid, Typography, Avatar, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRef, useState } from "react";
import CollaboratorCard from "./components/CollaboratorCard";
//import CustomVideoTable from "./components/CustomVideoTable";
// import CustomDropBox from "./components/CustomUploadZone";
import EventDetailCard from "./components/EventDetailCard";
import FilmingTutorialCard from "./components/FilmingTutorialCard";
import ShareLinkCard from "./components/ShareLinkCard";
// import CustomVideoTable from "../../components/CustomVideoTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUploadedList from "../../hooks/useUploadedList";
import useSequentialVideoUpload from "../../hooks/useSequentialVideoUpload";
import { goToCopilot, isEditStarted, isEventDate } from "../../utils/commonServices";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useEffect } from "react";
import CustomDropBox from "../../components/CustomUploadZone";
import CustomUserCard from "./components/CustomUserCard";
import "@fontsource/rubik";
import CustomSortOptions from "./components/CustomSortOptions";
//import CheckboxSelect from "./components/CheckboxSelect";
import CustomVideoTable from "../../components/CustomVideoTable";
import AuthLayout from "../layouts/AuthLayout";
import EditingStartedCard from "./components/EditingStartedCard";
import UpgradePlanCard from "./components/UpgradePlanCard";
import useConsentDetail from "./hooks/useConsentDetail";
import filmingTutorialImage from "../../assets/filmingTutorialCard.png"
import CustomCard from "../../components/CustomCard";
import { updateState } from "../../redux/commonSlice";
import PopupVideoPlayer from "../../components/PopupVideoPlayer";
import FinalVideoCompletedCard from "../completedvideo/components/FinalVideoCompletedCard";
import useCheckVideoArrival from "../completedvideo/hooks/useCheckVideoArrival";
import useEventDetails from "./hooks/useEventDetails";
import VideoEditedEventCard from "./components/VideoEditedEventCard";
import UploadFromProjectPopup from "./components/UploadFromProjectPopup";
import { sortByOptions } from "../../constants/staticData";
import useImportMediaFiles from "./hooks/useImportMediaFiles";
import DuplicatingFilesPopup from "./components/DuplicatingFilesPopup";
import InviteProjectMembersPopup from "./components/InviteProjectMembersPopup";
import AssignOwnerPopup from "./components/AssignOwnerPopup";
import RemoveUserConfirmationPopup from "./components/RemoveUserConfirmationPopup";
import RemoveOwnerPopup from "./components/RemoveOwnerPopup";
import butterfly from './../../assets/butterfly_navigation.png'
import ProjectMembersCard from "./components/ProjectMembersCard";
import ViewProjectMembersPopup from "./components/ViewProjectMembersPopup";
import { getAllEventNotes, getEventDetails, viewProjectMembers } from "./actions";
import { getProjectMediaFiles, getUserRoles } from "../../commonactions/actions";
import usePermissions from "../../hooks/usePermissions";
import { PERMISSION, uploadCategory } from "../../constants/constants";
import { checkNewVideoArrival } from "./actions";
import EventCreationDrawer from "../eventcreation";
import ViewNotesToEditorPopup from "./components/ViewNotesToEditorPopup";
import UploadDocumentsPopup from "./components/UploadDocumentsPopup";
import UploadSongPopup from "./components/UploadSongPopup";
import ViewSongDescriptionPopup from "./components/ViewSongDescriptionPopup";
import { checkTokenSession } from "../dashboard/actions";
import { forwardRef, useImperativeHandle } from 'react';
import { resetUploadState, setUploadState } from "../../redux/uploadSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    // minHeight:'100vh',
    // maxWidth:'100vw',
    // backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
    display: 'flex',
    width: '100%',
    minWidth: '860px',
    height: '85vh',
    // height:'100%',  
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  leftContainer: {
    width: '21.1%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '41px',
    // paddingLeft:'2.24%',
    height: '100%',

  },
  rightContainer: {
    width: '77.2%',
    display: 'flex',
    flexDirection: 'column',
    // marginRight:'4.9%',
    marginBottom: '51px',
    marginLeft: '1.7%',
    height: '100%',

  },
  scroll: {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }

  }
}));


const ProductionEventPage = forwardRef((props, ref) => {

  const classes = useStyles()
  const { videoTitle, videoSrc, uploadProps } = props
  const isLoading = false;
  const files = []
  const {
    selectedFiles, setSelectedFiles,
    uploadsInProgress, setUploadsInProgress,
    uploadStatus, setUploadStatus,
    progress, setProgress, uploadFiles,
    requestController, setRequestController,
    actionAfterSuccessfulUpload
  } = uploadProps;
  const [hasPermission] = usePermissions();
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const createEventReducer = useSelector((state) => state.createEventReducer);
  const teamsReducer = useSelector((state) => state.root.teamsReducer);
  const permissionsReducer = useSelector((state) => state.permissionsReducer);
  const projectReducer = useSelector((state) => state.projectReducer);
  const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
  const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
  //  const [refetchEventDetail] = useEventDetails(commonReducer.production_event_id)
  const [copyButtonText, setCopyButtonText] = useState("COPY LINK");
  const [shareButtonText, setShareButtonText] = useState("copy invitation");
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [sortBy, setSortBy] = useState('')
  const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
  const [eventData, setEventData] = useState({})
  const [uploadedVideosArray, setUploadedVideosArray] = useState([]);
  const [uploadedVideos, refetchList, uploadListStatus, uploadListLoading, enableGetUploadedMediaFiles] = useUploadedList(hasPermission(PERMISSION.PROJECT.MEDIA.VIEW.VIEW));
  // const [checkVideoArrival, recheckVideoArrival] = useCheckVideoArrival();
  const [getConsentDetails] = useConsentDetail();
  const statusRef = useRef();
  const underRevisionRef = useRef(false)
  const finalVideoRef = useRef();
  // const { files, 
  //   setFiles,
  //   progress, 
  //   uploadStatus,
  //   setUploadStatus, 
  //   setProgress,
  //   isLoading,
  //   requestController, 
  //   setRequestController,
  //   stopUpload, 
  //   stopList } = useSequentialVideoUpload(commonReducer.production_event_id, false, uploadedVideos, refetchList)  // useSequentialVideoUpload(eventId: , isGuestUser, uploadedVideos) 
  const [importFiles, importFilesStatus] = useImportMediaFiles("import-files")
  const [displayCheck, setDisplayCheck] = useState(false)
  const backButtonTitle = !!teamsReducer.selected_team ? "Back to Team Dashboard" : "Back to All Team Projects";

  const event = !!teamsReducer.selected_team ? teamsReducer.events?.find((event) => event.id == commonReducer.production_event_id) : commonReducer.events?.find((event) => event.id == commonReducer.production_event_id)

  const listOfAllEvents = !!teamsReducer.selected_team ? teamsReducer?.events?.map(({ id, name, owner_name }) => ({ id, name, owner_name })) : commonReducer?.events?.map(({ id, name, owner_name }) => ({ id, name, owner_name }))

  // refetch for useUploads.

  useImperativeHandle(ref, () => ({
    triggerChildFunction: refetchList,
  }));

  useEffect(() => {

    statusRef.current = commonReducer.event_details?.status_type_order;
    if (statusRef.current === 4) {
      if (commonReducer.event_details.final_video_id === commonReducer.event_details.revision.revision_video_id) {
        //dispatch(updateState({new_video: false}));
        underRevisionRef.current = commonReducer.event_details?.revision.submitted === true;
      } else {
        underRevisionRef.current = false;
      }
    }

  }, [commonReducer.event_details]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (statusRef.current == 3) {
        checkNewVideoArrival({ event_id: commonReducer.production_event_id }, dispatch)
      } else if (statusRef.current == 4 && underRevisionRef.current == true) {
        checkNewVideoArrival({ event_id: commonReducer.production_event_id, final_video_id: finalVideoRef.current }, dispatch)
      }
    }, 15000);

    return () => clearInterval(intervalId);

  }, [commonReducer.event_details.status_type_order, commonReducer?.event_details?.revision?.submitted]);

  useEffect(() => {
    hasPermission(PERMISSION.PROJECT.MEMBER.VIEW) && viewProjectMembers({ event_id: commonReducer.production_event_id }, dispatch, navigate, { commonReducer })
  }, [permissionsReducer.permissions_master[PERMISSION.PROJECT.MEMBER.VIEW]])

  useEffect(() => {
    finalVideoRef.current = commonReducer.event_details.final_video_id
  }, [commonReducer.event_details.final_video_id])

  // useEffect(()=> {
  //  // getEventDetails({id: commonReducer.production_event_id}, dispatch);

  //  const intervalId = setInterval( () => {
  //     if(statusRef.current == 3 || (statusRef.current == 4 && underRevisionRef.current == true)) {
  //       checkNewVideoArrival({event_id: commonReducer.production_event_id, final_video_id: finalVideoRef.current}, dispatch, refetchEventDetail)
  //     }
  //   }, 15000);

  //   return () => clearInterval(intervalId);


  // },[])
  useEffect(() => {
    if (hasPermission(PERMISSION.PROJECT.MEDIA.VIEW.VIEW)) {
      enableGetUploadedMediaFiles()
    }
  }, [commonReducer?.event_details])

  useEffect(() => {

    setUploadedVideosArray(uploadedVideos)

  }, [uploadedVideos, commonReducer?.event_details])


  const refreshMediaList = async () => {
    await refetchList()
    setDisplayCheck(true)
  }
  useEffect(() => {
    if (importFilesStatus === 'success') {
      refreshMediaList()
    }

  }, [importFilesStatus])




  useEffect(() => {
    checkTokenSession(dispatch)
    getEventDetails({ id: commonReducer.production_event_id }, dispatch)
    const payload = {
      event_id: commonReducer?.production_event_id,
      team_id: teamsReducer?.selected_team?.id
    }

    getUserRoles(dispatch, navigate, permissionsReducer, { event_id: commonReducer?.production_event_id })
  }, [])


  // useEffect(() => {

  //   const handleUnload = () => {
  //       dispatch(resetUploadState())
  //       localStorage.setItem("isReloading", true);
  //       dispatch(setUploadState({ upload_list_drawer: false, is_loading: false }))
  //   };
  //   window.addEventListener('beforeunload', alertUser)
  //   window.addEventListener("load", handleUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser)
  //     window.removeEventListener("load", handleUnload);
  //   }
  // }, [uploadProps.isLoading])



  const alertUser = e => {
    if (uploadProps.isLoading) {
      e.preventDefault()
      // window.history.forward();
      e.returnValue = 'Upload will be cancelled if you leave this page!!'
    }
  }

  useEffect(() => {
    // setFiles([])
    const selectedEvent = commonReducer.event_details
    //   if (selectedEvent == null) {
    //     setEventData({ name: commonReducer.production_events[0]?.name, start: commonReducer.production_events[0]?.start_date, end: commonReducer.production_events[0]?.end_date, genre: commonReducer.production_events[0]?.genre, code: commonReducer.production_events[0]?.collab_code })
    //   } else 
    if (Object.keys(selectedEvent)?.length > 0) {
      if (selectedEvent?.status_type_order < 3 && selectedEvent.consent_accepted == false) {
        getConsentDetails(commonReducer.production_event_id)
      }
      setEventData({ name: selectedEvent.name, editor_start_date: selectedEvent.editor_start_date, start: selectedEvent?.start_date, end: selectedEvent.end_date, eventType: selectedEvent.event_type, code: selectedEvent.collab_code, status: selectedEvent.status_type, consentStatus: selectedEvent.consent_accepted })
    }

  }, [commonReducer.production_event_id, commonReducer.event_details])



  const copyInvite = () => {
    setShareButtonText("Copied!")
    navigator.clipboard.writeText(`Hi! ${commonReducer.user_name} has invited you to be a contributor to their event. Please visit the given link and use following invitation code to collaborate to this event\n\nInvitation Code:  ${eventData.code}\n\n\n ${window.location.host}/invite-collaborator?c=${eventData.code}`)
    // navigator.clipboard.writeText(`${eventData.code}`)
    // setTimeout(() => {
    //   setShareButtonText("Copy code")
    // }, 1500)
  }

  const shareClickHandler = () => {
    const dataToBeShared = {
      // title: "This title is for sharing",
      text: `Hi! ${commonReducer.user_name} has invited you to be a contributor to their event. Please visit the given link and use following invitation code to collaborate to this event\n\nInvitation Code:  ${eventData.code}\n\n\n`,
      // url: `/guestuploads?e=${commonReducer.production_event_id}&n=${eventData?.name?.replace(/ /g, "%20")}&s=${eventData?.start}&end=${eventData?.end}`,
      url: `/`
    };
    // Check if navigator.share is supported by the browser

    if (navigator.share) {
      navigator
        .share(dataToBeShared)
        .then(() => {

        })
        .catch(() => {

        });
    } else {
      copyInvite()
      // console.log("Sorry! Your browser does not support Web Share API");
    }
  }

  const copyClickHandler = () => {
    setCopyButtonText("COPIED!")
    navigator.clipboard.writeText(`${window.location.host}/guestuploads?c=${eventData?.code}`)
    setTimeout(() => {
      setCopyButtonText("COPY LINK")
    }, 1500)

    // setSnackbarProps({ flag: true, message: `Copied to Clipboard: ${link} `, type: "success" })
  }


  function getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .map(word => word.charAt(0))
      .join('');
    return firstLetters;
  }


  return (

    <AuthLayout uploadProps={{ ...props.uploadProps }} sx={{ padding: '2.5% 3.9% 2.1% 2.2%' }}>
      <Box gap={2} className='flex row a-center j-end'>
        {commonReducer?.copilot_access && <img onClick={goToCopilot} src={butterfly} style={{ borderRadius: '5px', width: '230px', cursor: 'pointer' }} />}
        <CustomUserCard alertUser={alertUser}/>
      </Box>
      <Box container className={classes.container}>
        <Box className={classes.leftContainer}>
          <Box sx={{ height: { xs: '400px', sm: '400px', md: '72vh', lg: '66vh', xl: '68vh' }, marginBottom: { xs: '15px', md: '20px', lg: '23px', xl: '35px' } }}>
            <EventDetailCard event={event} />
          </Box>
          <Box sx={{ height: { xs: '60px', sm: '80px', md: '17.1vh', lg: '18.1vh', xl: '16.5vh' } }} >
            <FilmingTutorialCard onClick={() => dispatch(updateState({ play_tutorial: true }))} clickable={true} />
          </Box>

        </Box>

        <Box className={classes.rightContainer}>
          <Box sx={{ width: '100%', }}>
            {/* <Box gap={2} sx={{marginTop:{xs:'20px',sm:'24px', md:'28px', lg:'35px', xl:'49px',}, display:'flex', alignItems:'center', justifyContent:'end' }}>
                  <CustomUserCard/>
                </Box> */}
            <Box gap={1} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'black' }}>
              <IconButton  onClick={() => { navigate(-1) }}>
                <KeyboardBackspaceOutlinedIcon sx={{ color: 'black', }} />
              </IconButton>
              <Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '10px', sm: '11px', md: '12px', lg: '14px', xl: '20px' } }}>{backButtonTitle}</Typography>
            </Box>
          </Box>

          <Box style={{ display: 'flex', gap: '2.34%' }} sx={{ marginBottom: { xs: '15px', sm: '15px', md: '15px', lg: '20px', xl: '30px' }, marginTop: { xs: '8px', sm: '10px', md: '12px', lg: '14px', xl: '23px' }, width: '100%', }}>

            <Box sx={{ height: { xs: '100px', sm: '100px', md: '120px', lg: '19.5vh', xl: '20vh' }, width: '26%' }} container  >
              {/* <CollaboratorCard 
                          getFirstLetters={getFirstLetters} 
                          shareClickHandler={shareClickHandler} 
                          copyInvite={copyInvite} 
                          eventCode= {eventData?.code}
                          buttonState={shareButtonText} 
                          setButtonText= {setShareButtonText}
                          isDisabled = {commonReducer?.event_details?.status_type_order > 2 
                        }/> */}
              <ProjectMembersCard
                getFirstLetters={getFirstLetters}
                shareClickHandler={shareClickHandler}
                copyInvite={copyInvite}
                buttonState={shareButtonText}
                isDisabled={!hasPermission(PERMISSION.PROJECT.MEMBER.VIEW)}
              />
            </Box>
            <Box sx={{ height: { xs: '100px', sm: '100px', md: '120px', lg: '19.5vh', xl: '20vh' }, width: '74%' }} container>
              {commonReducer.event_details.status_type == "Review" ? (commonReducer.event_details?.revision?.submitted == true && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id ? <EditingStartedCard message="Requested revisions are being done to your Lucivideo!" note="Your current Lucivideo will be available until a newer version arrives." /> : < VideoEditedEventCard message={(commonReducer?.event_details?.revision?.id == null || commonReducer?.event_details?.revision?.submitted != true) ? "Your Lucivideo is ready!" : "Updates have been made to your Lucivideo."} />)
                : commonReducer.event_details.status_type == "Complete" ? <FinalVideoCompletedCard eventPage />
                  : commonReducer.event_details.status_type == "Post Production" ? <EditingStartedCard message="Woohoo! Your Lucivideo is currently being edited!" note="Video submissions are no longer accepted." />
                    : <ShareLinkCard
                      isDisabled={isLoading}
                      shareClickHandler={shareClickHandler}
                      // refetchEvent = {refetchEventDetail}
                      eventData={eventData}
                      copyClickHandler={copyClickHandler}
                      guestUploadsLink={<Typography width='100%' sx={{ fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '13px', xl: '16px' } }}>{`${window.location.host}/guestuploads?c=${eventData?.code}`}</Typography>}
                    />
              }
            </Box>
          </Box>

          <Box sx={{ display: 'flex', height: { xs: '60%', sm: '60%', md: '62%', lg: '64%', xl: '60%' }, }} gap={{ xs: 0.2, sm: 0.5, md: 2, lg: 2.5, xl: 3 }}>
            <Box flex={3.7} sx={{ paddingLeft: { xs: '5px', sm: '10px', md: '15px', lg: '20px', xl: '30px' }, }}>
              <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: { xs: '10px', sm: '14px', md: '16px', lg: '18px', xl: '25px' }, marginTop: { xs: '5px', sm: '5px', md: '5px', lg: '10px', xl: '20px' }, cursor: 'pointer' }} gap={{ xs: 0.3, sm: 0.5, md: 0.7, lg: 1, xl: 1.2 }} pr={0.5}>

                {hasPermission(PERMISSION.PROJECT.MEDIA.VIEW.VIEW) && <CustomSortOptions
                  sortByOptions={sortByOptions}
                  selectedSortbyValue={selectedSortbyValue}
                  setSelectedSortbyValue={setSelectedSortbyValue}
                // onChange={(newValue) => console.log(newValue)}
                />}

              </Box>
              {hasPermission(PERMISSION.PROJECT.MEDIA.VIEW.VIEW) && <CustomVideoTable
                sortBy={selectedSortbyValue}
                setSortBy={setSelectedSortbyValue}
                progress={progress}
                // stopUpload = {stopUpload}
                uploadStatus={uploadStatus}
                files={files}
                uploadedVideosArray={uploadedVideosArray}
                // stopList={stopList}
                isUploading={isLoading}
                refetch={refetchList}
                importFiles={importFiles}
                importFilesStatus={importFilesStatus}
                setUploadedVideosArray={setUploadedVideosArray}
              />}
            </Box>
            <Box flex={0.9} sx={{ height: '90%', width: '100%' }}>
              {isEditStarted(commonReducer?.event_details?.editor_start_date) ? <Box flex={0.9} sx={{ height: { xs: '55vh', md: '55vh', lg: '55vh', xl: '56vh' }, width: '100%', }}>
                <UpgradePlanCard />
              </Box>
                : <Box flex={0.9} sx={{ height: '90%', width: '100%' }}>
                  {hasPermission(PERMISSION.PROJECT.MEDIA.UPLOAD) && <CustomDropBox
                    isDisabled={
                      !isEventDate(eventData?.start, eventData?.editor_start_date) || isLoading
                    }
                    style={{ display: { xs: "none", md: "flex" } }}
                    // height={{xs: '50vh', md: '62vh', lg: '65vh'}}
                    // width="auto"
                    files={selectedFiles}
                    // category={uploadCategory.media}
                    accept = {{
                        "image/*": [],
                        "video/*": [],
                        "audio/*": []
                    }}
                    uploadedFiles = {uploadedVideosArray}
                    setFiles={setSelectedFiles}
                    setUploadStatus={setUploadStatus}
                    setProgress={setProgress}
                    setRequestController={setRequestController}
                    refetch={refetchList}
                    refetchEvent={() => getEventDetails({ id: commonReducer.production_event_id }, dispatch)}
                    listOfAllEvents={listOfAllEvents}
                    category = {uploadCategory.media}
                    extraPayload = {{event_id: commonReducer?.production_event_id}}
                  // productionFiles={productionFiles}
                  />}
                </Box>
              }
            </Box>
          </Box>

        </Box>
      </Box>
      {
        createEventReducer.open_form == true && <EventCreationDrawer getProjectDetails={() => getEventDetails({ id: commonReducer.production_event_id }, dispatch)} />
      }
      {
        commonReducer.play_tutorial == true && <PopupVideoPlayer />
      }
      {/* {commonReducer.import_files_confirmation_popup && <DuplicatingFilesPopup importFilesStatus={importFilesStatus} />} */}
      {/* {commonReducer.open_previous_project_media_popup && <UploadFromProjectPopup importFiles={importFiles}/>} */}
      {mediaFilesReducer.import_files_confirmation_popup && <DuplicatingFilesPopup importFilesStatus={importFilesStatus} displayCloseButton={displayCheck} />}
      {mediaFilesReducer.open_previous_project_media_popup && <UploadFromProjectPopup importFiles={importFiles} displayCloseButton={setDisplayCheck} />}


      {projectReducer.invite_project_members_popup == true && <InviteProjectMembersPopup />}

      {projectReducer.view_project_members_popup == true && <ViewProjectMembersPopup />}

      {projectReducer.assign_owner_popup == true && <AssignOwnerPopup />}

      {projectReducer.remove_user_confirmation_popup == true && <RemoveUserConfirmationPopup />}

      {projectReducer.remove_owner_popup == true && <RemoveOwnerPopup />}
      {projectReducer?.view_notes_to_editor_popup && <ViewNotesToEditorPopup event={event} />}

      {projectReducer?.upload_documents_to_editor_popup && <UploadDocumentsPopup uploadProps = {uploadProps} />}

      {projectReducer?.upload_song_popup && <UploadSongPopup uploadProps = {uploadProps} />}

      {projectReducer?.song_desc_popup && <ViewSongDescriptionPopup />}

    </AuthLayout>


  )
})

export default ProductionEventPage;