import { Box, IconButton,  TextField, Typography, getContrastRatio } from "@mui/material";
import { useEffect, useState } from "react";
import CustomAlert from "../../events/components/CustomAlert"
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../components/icons/CheckIcon";
import { useNavigate } from "react-router-dom";
import { generateLighterContrastColor } from "../../../utils/commonServices";
import useCustomTheme from "../../../theme/useCustomTheme";
import { updateProjectState } from "../../../redux/projectSlice";
import {  getAllEventSongs, updateEventSongText } from "../actions";
import useSequentialSongUpload from "../hooks/useSequentialSongUpload";
import SongDropzone from "./SongDropzone";
import UploadedSongsTable from "./UploadedSongsTable";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from "../../../components/CustomTooltip";
import { uploadCategory } from "../../../constants/constants";

const UploadSongPopup = (props) => {
  const {uploadProps} = props;
  const {
    selectedFiles, setSelectedFiles,
    uploadsInProgress, setUploadsInProgress,
    uploadStatus, setUploadStatus,
    progress, setProgress, uploadFiles,
    requestController, setRequestController,
    actionAfterSuccessfulUpload
  } = uploadProps;
   
      const dispatch = useDispatch()
      const navigate = useNavigate()
      const commonReducer = useSelector((state) => state.root.commonReducer);
      const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
      const projectReducer = useSelector((state) => state.projectReducer);
      const [uploadedSongs , setUploadedSongs] = useState (projectReducer?.uploaded_songs)
     
      const [theme] = useCustomTheme()
      const [editMode, setEditMode] = useState(false)
      const [songType, setSongType] = useState(commonReducer?.event_details?.song_type)
      const [disableUpload, setDisableUpload] = useState(false)

     
      const handleClose = () => {
        // if(isLoading === true){
        //   stopList( ()=> dispatch(updateProjectState({upload_song_popup : false}))  )
        // }else{
          dispatch(updateProjectState({upload_song_popup : false}))      
       // }
      
      };

      const handleFocus = (event) => {
        const length = event.target.value?.trim()?.length;
        event.target.setSelectionRange(length, length);
      };
  
      
    const saveSongText =()=>{
      const payload = {
        id: commonReducer?.production_event_id,
        song_type: songType
      }
      console.log({payload})
      updateEventSongText(payload, dispatch, convertToViewMode)
    }
    
    useEffect(()=> {
      getAllEventSongs({event_id: commonReducer?.production_event_id}, dispatch) 
    }, [])
  
    useEffect(() => {
       setUploadedSongs(projectReducer?.uploaded_songs)
  
    }, [ projectReducer?.uploaded_songs])
  
    const afterUploadCallback =()=>{
      
     // getAllEventSongs({event_id: commonReducer?.production_event_id}, dispatch, getEventSongsCallback) 
    }

    // const getEventSongsCallback =()=>{
    //   setIsDisabled(false)
    // }

   

    const convertToViewMode = ()=>{
      setEditMode(false)
    }

    const convertToEditMode = ()=>{
      setEditMode(true)
    }



    useEffect(() => {
      const handleKeyDown = (e) => {
        e.stopPropagation(); 
        if (e.key === ' ' || e.key === 'f' || e.key ===  'arrowright' || e.key === 'k'  || e.key === 'm'  || e.key === 'arrowleft') {
          // e.preventDefault(); 
        }
      };
  
      if (projectReducer.upload_song_popup) {
        document.addEventListener('keydown', handleKeyDown, true);
      }
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown, true);
      };
    }, [projectReducer.upload_song_popup]);
  
    // if (!projectReducer.upload_song_popup) return null;

  return (
    <CustomAlert 
      open={projectReducer.upload_song_popup} 
      handleClose={handleClose} 
      height={{xs:'550px',md:'550px',lg:'560px' ,xl:'770px'}}
      // height={{xs:'550px',md:'610px',lg:'605px' ,xl:'700px'}}
      width={{xs:'600px',md:'740px',lg:'780px',xl:'1170px'}}
      >       
        <Box sx={{display:'flex', flexDirection:'column', height:'100%', }} px={4} pt={1} pb={2}>
          <Box flexGrow={1} pt={{xs:3,sm:1, md:1.5, lg:2.5, xl:3}} sx={{overflowY:'hidden'}} >    
             
              <Box className='flex col' gap={{xs:0.5, lg:1}}  sx={{ height:'100%', }}>
                <Box className='flex j-between a-center' style={{width:{xs:'90%', md:'80%'}}}>
                  <Typography sx={{fontWeight:600, fontFamily:'Rubik', color:'#000', fontSize:{xs:'14px',sm:'14px',md:'16px',lg:'20px',xl:'30px'}}}>My Music</Typography>   
                </Box>
{/* 
                <Box pl={1} component='div' sx={{  maxHeight: {xs: '130px', sm: '120px', md: '210px', lg: '150px', xl: '215px'} ,  maxWidth:'91%', width:'91%'}}>

                    <Box className='flex'  sx={{ overflowY:'auto', }} >
                      {(editMode || commonReducer?.event_details?.song_type == '' || commonReducer?.event_details?.song_type == null ) ?  
                      ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) &&    <Box className='flex col j-center a-center' sx={{width:'100%'}}> 
                      <TextField
                        id="outlined-multiline-static"
                        autoFocus
                        disabled={isLoading}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={3}
                        inputProps={{
                          maxLength: 100
                        }}
                        size="small"
                        placeholder="Enter track name, mood, genre..."
                        onFocus={handleFocus}
                        defaultValue={commonReducer?.event_details?.song_type}
                        onChange={(event) => setSongType(  songType != ""
                          ? event.target.value
                          : event.target.value.trim())}
                        value={songType}
                        sx={{
                          mt:0.5,
            color:'#08231B',
                borderRadius:'5px',
                fontFamily: 'Rubik',
                marginBottom:0,
            '& .MuiInputBase-input': {
                        padding:0,
                        paddingX: '4px',
                        fontSize:{xs:'12px',md:'14px',lg:'16px', xl:'22px'},
                       
                        // color: theme.palette.inputs.main
                        color:'#08231B',
                        "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            
                    },
              },
              '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: '1px solid black', 
      },
    },
           }}
      // InputProps={{
      //       endAdornment: <InputAdornment position="end">
      //       <IconButton disabled={songType === ''} onClick={saveSongText}>
      //         <SaveOutlinedIcon />
      //       </IconButton>
      //       </InputAdornment>
      //     }}
      
                        />
                      <Box width='100%' className='flex row j-end a-start' >
                          <IconButton onClick={convertToViewMode} disabled={ commonReducer?.event_details?.song_type == '' || isLoading}>
                            <CloseIcon sx={{fontSize:{xs:'16px',md:'18px',lg:'20px', xl:'26px'}, color: 'black'}}/>
                          </IconButton>
                          <IconButton disabled={isLoading} onClick={saveSongText}>
                            <CheckOutlinedIcon sx={{fontSize:{xs:'16px',md:'18px',lg:'20px', xl:'26px'}, color: 'black'}}/>
                          </IconButton>
                      </Box> 
       </Box>: (
        <Box sx={{ width:'100%', display:'flex', justifyContent:'space-between', maxHeight:{xs:'40px',md:'50px',lg:'60px', xl:'80px'}, overflowY:'scroll',  padding:'5px', borderRadius:'3px', position:'relative', mb:1}}>
          <Typography style={{ whiteSpace: "pre-line", wordBreak:'break-all', }}   sx={{mt:0.5,  fontSize:{xs:'12px',md:'14px',lg:'16px', xl:'22px'}}}>{commonReducer?.event_details?.song_type}</Typography>
         {   ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) ) && <IconButton disabled={isLoading} onClick={convertToEditMode} sx={{position:'sticky',  right:0, top:0}}>
            <EditOutlinedIcon sx={{fontSize:{xs:'15px',md:'18px',lg:'20px', xl:'30px'}}}/>
          </IconButton>}
        </Box>
       )}
  </Box>
                </Box> */}

      
                 
              {   ((commonReducer?.event_details?.status_type_order < 3 || ( commonReducer.event_details?.revision?.submitted == false && commonReducer.event_details?.final_video_id == commonReducer.event_details?.revision?.revision_video_id)) && !editMode) &&    <Box  sx={{height:'100px', width: '90%' , minWidth: '320px', marginTop:{xs: '20px', md: '10px'}, marginBottom:{xs:'10px', sm:'15px',md:'5px',lg:'25px', xl:'70px'}, ml:1}}>
                    <SongDropzone
                    flex='row'
                    //  isDisabled={
                    //      isLoading 
                    //    }
                     files={selectedFiles}
                     setFiles={setSelectedFiles}
                     setUploadStatus={setUploadStatus}
                     uploadedFiles = {uploadedSongs}
                     setProgress={setProgress}
                     setRequestController={setRequestController}
                     category = {uploadCategory.songs}
                     onFileSelection = {handleClose}
                     extraPayload = {{event_id: commonReducer?.production_event_id}}
                    //  height={{xs: '80px',sm:'75px',md:'85px',lg:'105px', xl:'140px'}}
                     height={{xs: '90px',sm:'85px',md:'85px',lg:'115px', xl:'150px'}}
                     accept = {{
                      'audio/mpeg': ['.mp3', '.MP3'],
                      'audio/wav': ['.wav', '.WAV'],
                      'audio/ogg': ['.ogg', '.OGG'],
                      'audio/flac': ['.flac', '.FLAC'],
                      'audio/aiff': ['.aiff', '.AIFF'],
                      'audio/x-aiff': ['.aif', '.AIF'],
                      'audio/x-caf': ['.caf', '.CAF'],
                      'audio/mp4': ['.mp4', '.MP4', '.m4a', '.M4A'],
                      'audio/aac': ['.aac', '.AAC']
                    }}
                     setEnableFileUpload
                     
                   />
                   <Box className='flex row j-end ' gap={0.5} sx={{alignItems: 'flex-end'}} >
                    <Typography textAlign={"end"} sx={{fontSize: {xs:'9px',md:'10px',lg:'11px',xl:'15px'}, fontWeight:600, mt:'3px'}}>Audio files upto 500 mb.</Typography>
                    <CustomTooltip title=" Files Allowed: .wav, .ogg, .flac, .mp3, .mp4, .m4a, .aiff, .caf, .aac " >
                      <InfoOutlinedIcon sx={{fontSize:  {xs:'9px !important',md:'9px !important',lg:'10.67px !important', xl:'16px !important'}, color: '#000000',  marginBottom: '2px'}} />
                    </CustomTooltip>
                   </Box>
                  
                    </Box>}

                    
                    <Box height= '100%'  className= 'flex row j-between a-start' sx={{width:'100%', }}>
                  

                      <Box pl={1} component='div'  sx={{  maxHeight: {xs: '130px', sm: '120px', md: '210px', lg: '150px', xl: '215px'} ,  maxWidth:'85%', width:'85%'}}>

                        <UploadedSongsTable
                          // progress= {progress} 
                          // stopUpload = {stopUpload}
                          // uploadStatus = {uploadStatus}
                          // files = {files}
                          uploadedSongs={uploadedSongs}
                          // stopList={stopList}
                          // isUploading={isLoading}
                          height={{xs: '100px',sm:'85px',md:'100px',lg:'140px', xl:'180px'}}
                          
                          // setUploadedSongs={setUploadedSongs}
                        />

                      </Box>
                    
                    <Box height= '100%' className='flex col j-end a-end'>
                      <CheckIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)} 
                      onClick={()=> handleClose()}  
                      sx={{fontSize:{xs:'28px !important',sm:'30px !important',md:'42px !important', lg:'56px !important',xl:'75px !important'}, cursor:  'pointer', opacity: 0.5}}/>
                    </Box>
                </Box>
              </Box>

          </Box>
      </Box>
   
    </CustomAlert>

  )
}

export default UploadSongPopup