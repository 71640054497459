import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePermissions from "./usePermissions";
import { useDropzone } from "react-dropzone";
import { smartArrayMerge } from "../utils/commonServices";
import { setUploadState } from "../redux/uploadSlice";

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default (uploadProps)=> {

    const [tempFiles, setTempFiles] = useState([]);
    const dispatch = useDispatch()
    const uploadReducer = useSelector((state) => state.root.uploadReducer);
    const [isDropped, setIsDropped] = useState(false);

    const [hasPermission] = usePermissions()

    const {
        files,
        setFiles,
        setProgress,
        setUploadStatus,
        isDisabled = false,
        setRequestController,
        onFileSelection,
        accept,
        refetch,
        refetchEvent,
        setEnableFileUpload,
        category, extraPayload, isGuest = false,setIsPopupVisible,
        uploadedFiles
    } = uploadProps;

    const baseStyle = {
        // height:'20rem',
        // width:'30rem',
        // flex: 1,
       // height: height,
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2em",
        borderWidth: 3,
        borderRadius: 2,
        boxShadow: '0px 5px 15px #00000029',
        borderColor: "#FFFFFF",
        borderStyle: "dashed",
        backgroundColor: "#e6e6e6b3",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const {
        open,
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        disabled: isDisabled,
        accept: accept,

        onDrop: (acceptedFiles) => {
            setIsDropped(true)
           // setEnableFileUpload(false)
            // if(commonReducer.event_details.consent_accepted == false){
            //     dispatch(updateState({  open_agreement_popup : true }))
            // }else{
                // dispatch(updateState({  open_agreement_popup : false }))
                setTempFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file), category, extraPayload, isGuest 
                        })
                    )
                );
                dispatch(setUploadState({ upload_list_drawer: true }))
                !!setIsPopupVisible && setIsPopupVisible(false)
               
            // }
         
        },
    });

    useEffect(() => {
        const filteredNewFiles = smartArrayMerge(files, tempFiles)

        const tempAbort = filteredNewFiles?.map (()=> {
            const contollerObject = {controller: new AbortController(), trigger: false}
            return contollerObject;
           })
        setRequestController((last)=> [...last, ...tempAbort]) 
        const tempAbortTrigger = filteredNewFiles?.map (()=> {
            return {trigger: false}
           })
        dispatch(setUploadState({abort_controller: [...uploadReducer.abort_controller, ...tempAbortTrigger]}))
    
        const tempProgress = filteredNewFiles?.map (()=> {
             return 0;
            })
            setProgress((prevFiles) => [...prevFiles, ...tempProgress]); 
       // dispatch(setUploadState({progress: [...uploadReducer.progress, ...tempProgress]}))

        const tempStatus = filteredNewFiles?.map ((file)=> {
            if( uploadedFiles != null &&
                uploadedFiles.filter((item) => (item.name == file.name) )
                  ?.length > 0) {
                    return { type: "error", message: "File already exists" };
                  } else {
                    return { type: "standard", message: "Waiting..." };
                  }
            
           })
           setUploadStatus((prevFiles) => [...prevFiles, ...tempStatus]);
           dispatch(setUploadState({upload_status: [...uploadReducer.upload_status, ...tempStatus]}))
      
        setFiles((prevFiles) => [...prevFiles, ...filteredNewFiles]); 
        const tempSelectedFiles = filteredNewFiles?.map((selectedFile)=>{
            return {
              name: selectedFile.name,
              preview: selectedFile.preview,
              type: selectedFile.type,
              size: selectedFile.size,
              category: category,
              extraPayload 
            }
          })
          dispatch(setUploadState({selected_files: [...uploadReducer.selected_files, ...tempSelectedFiles]}))
          //!!onFileSelection && onFileSelection();
          //
      //  setFiles(tempFiles);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => tempFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [tempFiles]);

    useEffect(()=> {
        if(refetch) {
            refetch()
           // setFiles([])
        }
    }, [])


    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    // const browseFilesClickHandler = ()=>{
    //             open()   
    // }
return {
    open, 
    getInputProps, 
    getRootProps,
    style
}

}