/////////////////////////// VIDEO TABLE WITHOUT ACCORDIAN/////////////////////////////////


import { makeStyles } from "@mui/styles";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import CustomVideoLinearProgress from "./CustomVideoLinearProgress"; 
import "@fontsource/rubik";
import CustomTooltip from "./CustomTooltip";
import { downloadS3File, fileFormat, getFileType, removeExtension, sizeConverter, timeStampToNumberedDateString } from '../utils/commonServices'
import { deleteMedia, downloadMedia, favoriteMedia } from '../services/api'
import StarIcon from '@mui/icons-material/Star';
import { useMutation, useQuery } from "react-query";
import useUploadedList from "../hooks/useUploadedList"; 
import { apiConstant } from "../services/apiConstants"; 
import useSort from "../pages/events/hooks/useSort";
import useMediaDownload from "../pages/events/hooks/useMediaDownload";
import PopupVideoPlayer from "./PopupVideoPlayer";
import VideoPlayer from "../pages/completedvideo/components/VideoPlayer";
import { updateState } from "../redux/commonSlice";
import MediaPreview from "./MediaPreview";
import { abortUpload } from "../pages/upload/abortManager";


const useStyles = makeStyles((theme) => ({
  container: {
      width:'100%',
      borderCollapse: 'collapse',
     // paddingRight:'4%'
     scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
               display : 'none'
             }
  },
  tableHead:{
      marginBottom:'10px',
      height:'28.5px',
      fontFamily: "Rubik",
     // position: 'sticky',
      //top: '0px',
      //zIndex: 15,
     // background: 'white',
  },
  tableRowHead:{
      textAlign:'left',
      verticalAlign:'top',
      color:'#08231B',
      fontSize:'15px',
      fontFamily: "Rubik",
      fontWeight:500,
      letterSpacing: '0.75px',
      zIndex: 15
  },
  tableRowData:{
      borderBottom:'2px solid #EBEBEB',
      height:'68px',
      backgroundColor:'transparent',
      fontFamily: "Rubik",
      textAlign:'left',
      width:'100%',
      fontSize:'15px',
      // font: 'normal normal 600 15px/18px ',
      fontWeight:600,
      color:'#08231B',
      letterSpacing: '0.75px',
      zIndex: 15
  },
  tableCell:{
      textAlign:'right'
  },
  icons:{
      height:'18px',
      width:'18px'
  }
}));




const CustomGuestVideoTable = (props) => {

  const {progressFlag=true, files, isUploading, progress, stopUpload, uploadStatus, uploadedVideosArray=[],sortBy} = props;
  const classes = useStyles();
  const [enableDownload, setEnableDownload] = useState(false)
  const [payload, setPayload] = useState({id:''})
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [viewVideo, setViewVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("");
  // const [favorite, setFavorite] = useState({id:'', favorite: null})
  //const [uploadedVideos, refetchList] = useUploadedList(commonReducer.is_logged);
  const dispatch = useDispatch();
  const [downloadMedia] = useMediaDownload();
  const [sortedFiles] = useSort( uploadedVideosArray, sortBy)
  const [fileType, setFileType] = useState('')
  const uploadReducer = useSelector((state) => state.root.uploadReducer);
 

const {
  data: downloadData,
  error: downloadError,
  isLoading
} = useQuery(
  [`download${payload}`, payload],
  () => downloadMedia(payload),
  { enabled: enableDownload, retry: false }
);

//POST API Request  favorite video
const { mutate: addFavoriteMediaMutate, isLoading: addFavoriteMediaLoading } =
  useMutation(favoriteMedia, {
    onSuccess: (response, variables, context) =>
      favoriteMediaSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      favoriteMediaErrorHandler(error, variables, context),
  });

const  favoriteMediaSuccessHandler = (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data
  if (responseObject?.http_code == 200) {
  //   dispatch(updateState({ production_event_id: responseData?.id }));
  //   showSnackbar(responseObject);
  //   setCreateEventStatus("success");
 // refetchList()
  }

};

const favoriteMediaErrorHandler = (error, variables, context) => {
  // setSubmitFlag(false);
  // setCreateEventStatus("error");
  const errorObject = error?.response?.data;

};


  //DELETE API Request   video
  const { mutate: deleteMediaMutate, isLoading: deleteMediaLoading } =
  useMutation(deleteMedia, {
    onSuccess: (response, variables, context) =>
      deleteMediaSuccessHandler(response, variables, context),
    onError: (error, variables, context) =>
      deleteMediaErrorHandler(error, variables, context),
  });

const  deleteMediaSuccessHandler = (response, variables, context) => {
  const responseObject = response?.data;
  const responseData = responseObject?.data
  if (responseObject?.http_code == 200) {
  //   dispatch(updateState({ production_event_id: responseData?.id }));
  //   showSnackbar(responseObject);
  //   setCreateEventStatus("success");
  //refetchList()
  }

};

const deleteMediaErrorHandler = (error, variables, context) => {
  // setSubmitFlag(false);
  // setCreateEventStatus("error");
  const errorObject = error?.response?.data;
};


  const favoriteHandler =(file)=>{
      // console.log(file.id)
      // console.log(file.favourite)
      addFavoriteMediaMutate({id:file?.id, favourite: !file?.favourite })
     
  }

  const handleDelete =  (id) => {
       deleteMediaMutate({id})
      // refetchList()
    };

    const videoDownloadHandler =  (file)=>{
      if(file?.video_url != null && file?.video_url != "") {
        downloadS3File(file.download_link)
      }
     
     // console.log('Video download')// download video 
      // const url = 'https://s3.us-central-1.wasabisys.com/files-storage-staging/final_video/2/wasabi_sample.mp4';
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'video.mp4');
      // link.target = '_blank';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);  
  }
  const viewMediaHandler = (file)=> {
    if(file?.video_url != null && file?.video_url != "") {
      // console.log(getFileType(file?.name))
      setFileType(getFileType(file?.name))
      setVideoSrc(file.video_url)
      dispatch(updateState({open_media_preview: true}))
    }

  }

    const handleAbort = (fileIndex) => {
      abortUpload(fileIndex);
      // Optionally dispatch an action to remove the upload from Redux
    //  dispatch({ type: 'REMOVE_UPLOAD', payload: id });
    };

  const iconButtonActions = (
      <Box >
          <IconButton >
           <DeleteIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>
          </IconButton>
         <IconButton >
              <StarOutlineIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},'& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>
         </IconButton>
          <IconButton disabled >
                 <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},'& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>
         </IconButton>
 </Box>
    )

return (

  <Box pr={0.5}>
  <Box sx={{ display:'flex'}} className={classes.tableHead}>
    <Box flex={5} style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24,  minWidth:'220px'}}><Typography  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik' }}>File Name</Typography></Box>

    <Box flex={0.7} style={{  minWidth:'90px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'},  fontFamily: 'Rubik' }}>File Type</Typography></Box>

    <Box flex={0.7} style={{  minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik'  }}>Size</Typography></Box>

    <Box flex={0.7} style={{  minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik'}}>Upload Date</Typography></Box>

    <Box flex={0.7}  style={{  minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik'}}>Uploaded By</Typography></Box>

    <Box flex={1}  className={classes.tableCell} ><Typography  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik',}}>{files?.length > 0 && "Status"}</Typography></Box>
  </Box>
  <Box className={classes.container} 
  component = 'div'
      sx={{
          overflowY: 'scroll',
          height: {xs:'35vh',sm:'35vh',md: '37vh',lg:'39vh', xl: '48vh'}, 
                  minHeight:'300px'
      }}
  >
      {/* <table className={classes.container}> */}
          {/* <thead className={classes.tableHead}>
              <tr className={classes.tableRowHead}>
                  <td ><Typography  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik' }}>File Name</Typography></td>
                  <td><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'},  fontFamily: 'Rubik' , pl:2}}>File Type</Typography></td>
                  <td><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik'  }}>Size</Typography></td>
                  <td><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik'}}>Upload Date</Typography></td>
                  <td><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik', pr:2}}>Uploaded By</Typography></td>
                  {/* <td><Typography  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'15px'}, fontFamily: 'Rubik',}}>{files?.length > 0 && "Status"}</Typography></td> 
              </tr>
          </thead> 

          <tbody> */}
              {files?.length > 0 && files?.map((file, index) => (
                  <Box className={classes.tableRowData} key={file.size} sx={{display:'flex', alignItems:'center', }}>
                   <CustomTooltip title = {file?.name} >
                       <Box flex={5} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, minWidth:'220px'}} ><Typography sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik'}}>{removeExtension(file?.name)}</Typography></Box>
                   </CustomTooltip>

                   <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'90px'}} ><Typography textAlign='center'  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600,  fontFamily: 'Rubik'}}>{file?.type}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'60px'}}  ><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'70px'}} ><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(Math.floor(Date.now()/1000))}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'60px'}} ><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik',}}>{commonReducer.is_logged ? commonReducer.user_name : "Guest Cam"}</Typography></Box>

                    <Box flex={1}  sx={{display:'flex', justifyContent:'end', backgroundColor:'transparent', minWidth:'100px', }} ><Box textAlign='end' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik',width:'100%',display:'flex', justifyContent:'end', }}>{progressFlag? <CustomVideoLinearProgress
            // imageURL = {imgSrc.find((img)=> img.name == file.name)?.image}
                      //  progress={progress[index]}
                      //  status={uploadStatus[index]}
                      //  stopUpload={() => stopUpload(index)}
                      progress= {uploadReducer?.progress[index]}
                      status={uploadReducer.upload_status[index]}
                      // stopUpload={() => handleAbort(index)}
                       fontSize = {12}
                       bar = {5}
          /> : iconButtonActions}</Box>
                    </Box>
                  </Box>
              ))}

             {sortedFiles?.length > 0 && sortedFiles?.map((file, index) => (
                <Box className={classes.tableRowData} key={file?.size + index} sx={{display:'flex',  alignItems:'center'}}>
                    <CustomTooltip title = {(file?.video_url == null || file?.video_url == "") ? "Preview will be available after some time" : file?.name} >
                       <Box flex={5} className='flex'  style={{maxWidth:'220px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: 24, wordWrap:'break-word', minWidth:'220px'}}><Typography onClick={()=>viewMediaHandler(file)}  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, wordWrap:'break-word', fontFamily: 'Rubik', cursor: (file?.video_url != null && file?.video_url != "") && 'pointer'}}>{removeExtension(file?.name)}</Typography></Box>
                    </CustomTooltip> 

                    <Box flex={0.7}  className='flex j-center'  style={{  minWidth:'90px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik' }}>{fileFormat(file?.name)}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box>

                    <Box flex={0.7}  className='flex j-center'  style={{ minWidth:'70px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file?.upload_time)}</Typography></Box>

                    <Box flex={0.7} className='flex j-center' style={{ minWidth:'60px'}}><Typography textAlign='center' sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, fontWeight:600, fontFamily: 'Rubik'}}>{file?.uploaded_by}</Typography></Box>

                    <Box flex={1}  sx={{ display:'flex', justifyContent:'end'}} className={classes.tableCell}><Box  sx={{fontSize: {xs:'8px',md:'9px',lg:'10px',xl:'14px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={0.5}>
                  { commonReducer.event_details?.status_type_order < 3 &&
                       <>  <IconButton  onClick={()=>handleDelete(file.id)} sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}>
                           <DeleteIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>
                         </IconButton> 
                             
                             
                          <Checkbox 
                           sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'}},  zIndex:0}} 
                           onClick={()=>favoriteHandler(file)} 
                           checked={file?.favourite}  
                           icon={<StarOutlineIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           }}}/>} 
                           checkedIcon={<StarIcon sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},
                           } }}/>} /></>
                               }
                          <CustomTooltip title= {(file?.video_url == null || file?.video_url == "") ? "Will be available for download after some time" : "Download"} >
                            <IconButton 
                              disabled={file?.video_url == null || file?.video_url == ""}  
                              onClick={()=>videoDownloadHandler(file)} 
                              sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'}, 
                                  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}} 
                            >
                              <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'8px',md:'10px',lg:'13px',xl:'18px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'8px',md:'10px',lg:'13px',xl:'18px'},}}}/>
                            </IconButton>
                          </CustomTooltip>
                    </Box></Box>
               </Box>
               ))}
          {/* </tbody> */}
      {/* </table> */}

      <Box sx={{width:'100%', textAlign:'center', display:'grid', placeContent:'center', marginTop: '10%' }} >
      {(uploadedVideosArray?.length == 0 && files?.length == 0 ) && <Typography px={20} sx={{color:'#878887', fontSize:{xs:'20px',sm:'24px',md:'28px',lg:'33.33px',xl:'50px'}, fontWeight: 300, fontFamily: "Rubik"}} variant="h3">Start your project by uploading pre-existing files.</Typography>}
      </Box>
      {
        commonReducer.open_media_preview == true && <MediaPreview url={videoSrc} fileType={fileType}/>
      }
  </Box >
  </Box>
)
}

export default CustomGuestVideoTable