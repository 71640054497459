import { useEffect, useRef, useState } from "react";
import Bottleneck from "bottleneck";
import { useDispatch, useSelector } from "react-redux";
import { base64ToBlob, isAcceptedFormat } from "../../../utils/commonServices";
import { getAllAssetsApi, getAllEventNotesApi, getAllEventSongsApi, guestUploadVideo, uploadChunkGuestApi, uploadVideo } from "../../../services/api";
//import useUpload from "../hooks/use";
import { setSnackbar } from "../../../redux/messageSlice";
import { uploadChunkApi } from "../../../services/api";
import useUploadActions from '../useUploadActions';
import { determineFileType } from '../commonServices';
import { fileFormat, removeExtension } from '../../../utils/commonServices';
import { uploadCategory } from "../../../constants/constants";
import { resetUploadState, setUploadState } from "../../../redux/uploadSlice";
import { updateProjectState } from "../../../redux/projectSlice";
import { setAssetState } from "../../../redux/assetSlice";
import useUploadedList from "../../../hooks/useUploadedList";
import { getAllEventNotes, getAllEventSongs } from "../../events/actions";
import { getAllAssets } from "../../brandbox/actions";
import { abortUpload, addAbortController, removeAbortController } from "../abortManager";

export default () => {
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const [uploadedVideos, refetchList, uploadedListLoading] = useUploadedList(!!commonReducer.production_event_id);
  const [progress, setProgress] = useState([]);
  const [chunkProgress, setChunkProgress] = useState(0);
  const [requestController, setRequestController] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [imgSrc, setImgSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadsInProgress, setUploadsInProgress] = useState([]);


  const [isLoading, setIsloading] = useState(false);
  const initialUploadState =()=>{
    syncFilesRef([])
    syncProgressRef([])
    syncStatusRef([])
    controllerRef.current=[]
    setProgress([])
    setRequestController([])
    setUploadStatus([])
    setImgSrc([])
    setUploadsInProgress([])
    setIsloading(false)
    setFiles([])
    setSelectedFiles([])
    dispatch(setUploadState({uploading_file_index:null}))
  }


  const uploadReducer = useSelector((state) => state.root.uploadReducer);
  const dispatch = useDispatch();
  const maxSizeGb = 10;
  const { fileUploadPreflight, fileUploadPostflight } = useUploadActions();
  const filesRef = useRef([]); // Mutable reference to the files array
  const progressRef = useRef([])
  const controllerRef = useRef([]);
  const statusRef = useRef();
  const abortAllRef = useRef(false);

  // Sync the filesRef whenever the files array changes
  const syncFilesRef = (newFiles) => {
    filesRef.current = newFiles;
  };

  const syncProgressRef = (newArray) => {
    progressRef.current = newArray;
  };
  const syncControllerRef = (newArray) => {
    controllerRef.current = newArray;
  };

  const syncStatusRef = (newArray) => {
    statusRef.current = newArray;
  };
  const syncAbortAllRef = (newValue) => {
    abortAllRef.current = newValue;
  };

  //Callback to grab a function from child components to be executed here after api calls



  // Initialize Bottleneck for sequential chunk uploads
  const chunkLimiter = new Bottleneck({
    maxConcurrent: 1,
    minTime: 200, // Adjust based on your API rate limits
  });

  const fileLimiter = new Bottleneck({
    maxConcurrent: 1,
    minTime: 200, // Adjust based on your API rate limits
  });

  const getRefetchFunction = (category) => {
    // console.log('post flight refetch function.............................', category)
    switch (category) {
      case uploadCategory.assets:
        return getAllAssets(dispatch);

      case uploadCategory.media:
        return refetchList();

      case uploadCategory.notes:
        return getAllEventNotes({ event_id: commonReducer?.production_event_id }, dispatch);

      case uploadCategory.songs:
        return getAllEventSongs({ event_id: commonReducer?.production_event_id }, dispatch);

      default:
        return 'No action';
    }
  };

  // console.log(chunkProgress , 'chunk upload loop...............................................................')

  const updateStatus = (fileIndex, newStatus) => {
    const tempStatus = statusRef.current?.map((status_item, status_index) => {
      if (status_index == fileIndex) {
        return newStatus
      } else {
        return status_item;
      }
    })
    console.log("Temp status-------------------------------------------------->>>>>>", statusRef.current)
    dispatch(setUploadState({ upload_status: tempStatus }));
    syncStatusRef(tempStatus)
  }

  // Function to simulate chunk upload
  const uploadChunk = async (media_id, chunkData, fileIndex, updateProgress, abortController, isGuest) => {
    dispatch(setUploadState({ uploading_file_index: fileIndex }));
    if(abortAllRef.current){
      return;
    }
     
    const payload = chunkData;
    const controller = new AbortController();
    addAbortController(fileIndex, controller);
    const apiOptions = {
      data: payload,
      progress: updateProgress,
      headers: { "Content-Type": "multipart/form-data" }, // Correct header format
      controller: controller
    }

    try {
      const response = isGuest ? await uploadChunkGuestApi(apiOptions) : await uploadChunkApi(apiOptions);
      // console.log(`Chunk ${chunkIndex} uploaded successfully.`, response?.data);
      // 
      if (response?.data?.message === 'Chunk uploaded successfully') {
        // console.log(`Chunk ${chunkIndex} uploaded successfully.`, response?.data);
        //  setChunkProgress(0)
        // Remove controller on success
        removeAbortController(fileIndex);
        return { status: "success", data: response?.data };  // Indicate success
      } else {
        throw new Error(`Server responded with status`);
      }

    } catch (error) {
      // setChunkProgress(0);
      console.error(`Error uploading chunk ${chunkData.data.index}:`, error.message);

      return { status: "error", data: error.message };  // Indicate failure
    }
  };


  const uploadChunkWithRetry = async (fileId, chunk, fileIndex, updateProgress, abortController, isGuest, maxRetries = 5, delay = 1000) => {
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      if(abortAllRef.current)
      return;
      try {
        // Attempt to upload the chunk
        const response = await uploadChunk(fileId, chunk, fileIndex, updateProgress, abortController, isGuest);

        console.log("rsponse>>>>>>>>>>>>>>>>", response)
        // Check for a successful response
        if (response.status == 'success') {
          console.log(`Chunk ${chunk.data.index} uploaded successfully on attempt ${attempt}.`);
          return true;  // Exit function on success
        } else {
          throw new Error(response.data);
        }

      } catch (error) {
        // console.error(`Error uploading chunk ${chunk.data.index}, attempt ${attempt}:`, error.message);
        console.log("error", error)
        if (error.message == "canceled") {
          console.log("here")
          updateStatus(fileIndex, { type: "error", message: "Canceled!" })
          return false
        }
        // Wait before retrying
        if (attempt < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, delay * attempt));  // Exponential backoff
        } else {
          console.error(`Chunk ${chunk.data.index} failed after ${maxRetries} attempts.`);
          updateStatus(fileIndex, { type: "error", message: "Upload Failed!!" })
          return false;  // Final failure after retries
        }
      }
    }
  };


  // Function to handle file processing
  const processFileUpload = async (file, fileIndex) => {
    if(uploadReducer.upload_status[fileIndex]?.message == "File already exists") {
      console.log("already exists")
      throw new Error(`file_exists`);
    }
   
// setIsloading(true)
    const chunkSize = 1024 * 1024 * 10; // Example: 10MB chunk size
    const totalChunks = Math.ceil(file.size / chunkSize);
    const fileType = determineFileType(file)
    const preFlightPayload = {
      data: {
        name: file?.name,
        content_type: fileType,
        size: file?.size,
        total_chunk_count: totalChunks,
        ...file.extraPayload
      },
      category: file.category,
    }
    const media_id = await fileUploadPreflight(preFlightPayload,  file.isGuest ) // First API call to send file details
    if (!!media_id) {
      // let tempChunksArray = []
      let successCounter = 0
      let tempFileProgress = 0
      //setChunkProgress(0)
      for (let i = 1; i <= totalChunks; i++) {
        const updateFileProgress = (tempProgress) => {
          tempFileProgress = parseFloat((i - 1) / totalChunks) * 100.0 + parseFloat(tempProgress / totalChunks)

          const temp = progressRef.current?.map((num, index) => {
            if (fileIndex == index) {
              return tempFileProgress;
            } else {
              return num;
            }
          });
          dispatch(setUploadState({ progress: temp }))
        }

        const start = (i - 1) * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);

        const payloadData = {
          data: {
            file: chunk,
            index: i,
            filename: `${removeExtension(file?.name)}_${i}`,
            size: chunk?.size,
            id: media_id,
          },
          category: file.category
        }

        const abortController = controllerRef.current[fileIndex]
        console.log("abort controller...................>>>>>>>>>>>", controllerRef.current)
        //  tempChunksArray.push(payloadData);
        // Schedule each chunk upload with the limiter
        const success = await chunkLimiter.schedule(() =>
          uploadChunkWithRetry(media_id, payloadData, fileIndex, updateFileProgress, abortController, file.isGuest)
        );

        // If a chunk ultimately fails, stop the process
        if (success) {
          successCounter++
        } else {
          throw new Error(`Failed to upload chunk ${i}, stopping file upload.`);
        }
      }
      // After all chunks are uploaded, trigger integration
      if (successCounter == totalChunks) {
        const payload = {
          data: {
            id: media_id
          },
          category: file.category
        }

        const postFlightCallback = (progress) => {
          setChunkProgress(progress)
          // refetchList()
          !file.isGuest && getRefetchFunction(file.category)
          updateStatus(fileIndex, { type: "success", message: "Uploaded Successfully!" })
          if (fileIndex === files.length - 1) {
            // console.log('This is the last file:');
            setIsloading(false)
            // Perform the action for the last file here
          } 
        }
        if(abortAllRef.current){
         // abortUpload(fileIndex)
          return;
        }
        await fileUploadPostflight(payload, file.isGuest, postFlightCallback)

        if (fileIndex == uploadsInProgress?.length) {

        }

      } else {
        updateStatus(fileIndex, { type: "error", message: "Upload Failed!" })
        console.log("Few parts of file are missing. try to re upload")
      }
    }

    // await finalizeFileUpload(fileId);
  };

  // Main function to handle multiple files
  const uploadFiles = async () => {
    // dispatch(setUploadState({ upload_list_drawer: true }))
    setIsloading(true)
    for (let index = 0; index < filesRef.current.length; index++) {
      const file = filesRef.current[index];
      let tempUploads = [];

      // Add file to uploads-in-progress state
      setUploadsInProgress((prev) => {
        if (!prev.includes(file.name)) {
          tempUploads = [...prev, file.name];
        } else {
          tempUploads = prev;
        }
        return tempUploads;
      });

      // Dispatch to Redux
      dispatch(
        setUploadState({
          is_loading: true,
          in_progress_files: tempUploads, // Directly pass the array for Redux
        })
      );

      try {
        if (!uploadsInProgress.includes(file.name)) {
          const tempTotalProgress =
            (index / filesRef.current.length) * 100 +
            uploadReducer.file_progress / filesRef.current.length;

          dispatch(
            setUploadState({ total_progress: tempTotalProgress })
          );
         
          await processFileUpload(file, index);
        }
      } catch (error) {
        console.log({error})
        
       // console.error(`Error uploading file ${file.name}:`, error);
      }
    }

    // Reset upload state after a delay
    setTimeout(() => {
       
      //  controllerRef.current=[]
       dispatch(resetUploadState());
     
     //  initialUploadState()
       
    }, 1500);
  };

  // useEffect(()=> {
  //   return () => {
  //     syncFilesRef([])
  //     syncProgressRef([])
  //     syncStatusRef([])
  //     controllerRef.current=[]
  //     initialUploadState()
  //   }

  // }, [])

  useEffect(() => {
    syncProgressRef(progress)
  }, [progress])

  useEffect(() => {
    syncStatusRef(uploadStatus)
  }, [uploadStatus])


  useEffect(() => {

    syncFilesRef(selectedFiles); // Sync the reference
    if (selectedFiles?.length > 0) {
      // dispatch(setUploadState({ upload_list_drawer: true }))
    }

    if (!uploadReducer.is_loading) {
      uploadFiles()
    }
    //uploadFiles()
  }, [selectedFiles]);

  useEffect(()=> {
    if(uploadReducer.abort_all) {
      // syncAbortAllRef(true)
   
      initialUploadState()
    
    }
  }, [uploadReducer.abort_all])

  return {
    files,
    setFiles,
    selectedFiles, setSelectedFiles,
    uploadsInProgress, setUploadsInProgress,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
    progress,
    setProgress,
    imgSrc,
    setImgSrc,
    isLoading,
    requestController,
    setRequestController,
    initialUploadState
    //  stopUpload,
    // stopList,
  };
}
