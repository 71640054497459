import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadState } from '../../../redux/uploadSlice';
import CustomButton from '../../../components/CustomButton';
import { abortUpload } from '../abortManager';
import { logout } from '../../../utils/commonServices';

const BrowserAlertPopup = ({ message, onCancel, onContinue, uploadProps }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const dispatch = useDispatch()
  const uploadReducer = useSelector((state) => state.root.uploadReducer);

  useEffect(() => {
    // Detect dark or light theme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleThemeChange = (e) => setIsDarkMode(e.matches);

    setIsDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleThemeChange);

    // Disable page interaction
    const body = document.body;
    const originalOverflow = body.style.overflow;
    body.style.overflow = 'hidden';

    return () => {
      mediaQuery.removeEventListener('change', handleThemeChange);
      body.style.overflow = originalOverflow;
    };
  }, []);

//   useEffect(() => {
//     // Detect dark or light theme
//     const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
//     const handleThemeChange = (e) => setIsDarkMode(e.matches);

//     setIsDarkMode(mediaQuery.matches);
//     mediaQuery.addEventListener('change', handleThemeChange);

//     return () => mediaQuery.removeEventListener('change', handleThemeChange);
//   }, []);

   const handleClose = () => {
      abortUpload(uploadReducer?.uploading_file_index)
      uploadProps.setSelectedFiles([])
      dispatch(setUploadState({ upload_list_drawer: false, is_loading: false, abort_all: true ,uploading_file_index:null, browser_alert_popup:false}))
      setTimeout(() => {
        dispatch(setUploadState({abort_all:false}))
      }, 50);
      uploadReducer.logout_clicked && logoutUser()
    };

    const logoutUser =()=>{
        logout(dispatch);
        window.localStorage.setItem('logout_all', "true")
        window.location.href=`${process.env.REACT_APP_COPILOT_URL}/redirect?request=logout`;
    }

  return (
    <Box sx={{
      height:'100vh', 
      width:'100vw',
      backgroundColor:'transparent', 
      position: 'fixed',
      top: '0%',
      zIndex:1300 
      
    }}>
    <Box
      sx={{
       position: 'fixed',
        top: '1%',
        left: '50%',
        transform: 'translateX(-50%)',
        maxWidth:'500px',
        minWidth: '300px',
        padding: '20px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        backgroundColor: isDarkMode ? '#333' : '#fff',
        color: isDarkMode ? '#fff' : '#000',
        // zIndex: 1300,
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
      
    >
      <Box sx={{ marginBottom: '20px', fontSize: '16px', textAlign:'left' }}>{'Uploads are in progress. Do you want to stop upload or stay?'}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' ,px:2, gap:1}}>
        <Button
             onClick={()=>dispatch(setUploadState({browser_alert_popup:false, logout_clicked: false}))}
          sx={{
            backgroundColor: isDarkMode ? '#555' : '#f0f0f0',
            color: isDarkMode ? '#fff' : '#000',
            textTransform: 'none',
            fontSize:'14px !important',
            fontWeight: 'bold',
            width:'120px',
            padding: '4px 16px',
            '&:hover': {
              backgroundColor: isDarkMode ? '#666' : '#e0e0e0',
            },
          }}
        >
          Stay
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: isDarkMode ? '#007bff' : '#007bff',
            color: '#fff',
            fontSize:'14px !important',
            textTransform: 'none',
            fontWeight: 'bold',
            padding: '4px 16px',
            width:'120px',
            '&:hover': {
              backgroundColor: isDarkMode ? '#0056b3' : '#0056b3',
            },
          }}
        >
          {`${uploadReducer.logout_clicked ? "Logout" : "Stop Upload"}`}
        </Button>
      </Box>
    </Box>
    </Box>
  );
};

export default BrowserAlertPopup;



