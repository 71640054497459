import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { customTheme as theme } from "../theme/customTheme";
import VideoImageThumbnail from "react-video-thumbnail-image";
import { useEffect, useState } from "react";
import useUploadedList from "../hooks/useUploadedList";
import { apiBaseUrl } from "../constants/constants";
import {
  sizeConverter,
  timeStampToDateTime,
  timeStampToNumberedDateString,
} from "../utils/commonServices";
import { useSelector } from "react-redux";
import CustomVideoLinearProgress from "./CustomVideoLinearProgress";
import CustomTooltip from "./CustomTooltip";
import CustomButton from "./CustomButton";
//import CancelAlert from "./CancelAlert";
import "@fontsource/rubik";
import useCustomTheme from "../theme/useCustomTheme";
import { abortUpload } from "../pages/upload/abortManager";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
  },
  container: {
    width: 356,
    height: 200,
    borderRadius: 20,
  },
  img: {
    display: "block",
    width: "100%",
    // height: 'auto'
    height: "100%",
    borderRadius: 20,
  },
  hide: {
    display: "none",
  },
}));

const CustomVideoUploads = (props) => {
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const uploadReducer = useSelector(state => state.root.uploadReducer)
  const classes = useStyles();
  const [theme ] = useCustomTheme()
  const {
    files,
    progress,
    uploadedVideosArray,
    mdHeight,
    uploadStatus,
    stopUpload,
    stopList,
    isUploading = false,
  } = props;

  const date = Date.now() / 1000;

    const handleAbort = (fileIndex) => {
      abortUpload(fileIndex);
      // Optionally dispatch an action to remove the upload from Redux
    //  dispatch({ type: 'REMOVE_UPLOAD', payload: id });
    };

  return (
    <Box
      component="div"
      style={{ p: 16, overflowY: "scroll" }}
      sx={{
        maxHeight: { xs: "36vh", sm: "53vh", md: `${mdHeight}vh`, lg: "60vh" },
        width: {xs: '80vw', sm: '70vw'}
      }}
      gap={1}
    >
      {/* <Box style={{ display: isUploading ? "flex" : "none" }}>
        <CustomButton
          btnText="CANCEL ALL"
          variant="text"
          btnStyle={{ color: "#ED1B2F" }}
          onClick={() => setOpenCancelAlert(true)}
        />
      </Box> */}

      {files?.map((file, index) => {
        return (
          <Box m={2} sx={{ borderBottom: 2, borderBottomColor: '#EBEBEB', pb: 2 }}>
            <Box
              width="100%"
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: theme.palette.primary.light,
                fontStyle: "italic",
              }}
              mt={1}
              mb={1}
            >
              <CustomTooltip title={file.name}>
                <Typography
                component='span'
                  sx={{
                    color: '#08231B',
                    fontSize: {xs:8, sm: 10},
                    fontWeight: 600,
                    fontFamily: 'Rubik',
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "60%",
                  }}
                >
                  {file.name}
                </Typography>
              </CustomTooltip>
              <Typography sx={{color: '#08231B',
                    fontSize:{xs: 8, sm: 10},
                    fontFamily: 'Rubik',
                    fontWeight: 600,}} >{sizeConverter(parseInt(file.size))}</Typography>
            </Box>
            <CustomVideoLinearProgress
              // imageURL = {imgSrc.find((img)=> img.name == file.name)?.image}
              // progress={progress[index]}
              // status={uploadStatus[index]}
              progress= {uploadReducer?.progress[index]}
              status={uploadReducer.upload_status[index]}
              // stopUpload={() => handleAbort(index)}
              // stopUpload={() => stopUpload(index)}
              fontSize = {20}
              bar = {8}
            />
          </Box>
        );
      })}
      {uploadedVideosArray != null &&
        uploadedVideosArray?.length > 0 &&
        uploadedVideosArray?.map((uploadedVideo) => {
          return (
            <Box m={2} sx={{ borderBottom: 1 }}>
              <Box
                width="355px"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: theme.palette.primary.light,
                  fontStyle: "italic",
                }}
                mt={1}
              >
                <CustomTooltip title={uploadedVideo.name}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontFamily: 'Rubik',
                      textOverflow: "ellipsis",
                      width: "60%",
                    }}
                  >
                    {uploadedVideo.name}
                  </span>
                </CustomTooltip>
                <span>{sizeConverter(parseInt(uploadedVideo.size))}</span>
              </Box>
              <Box
                style={{
                  width: '100%',
                  height: 40,
                  position: "relative",
                  borderRadius: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between", //'space-between'
                }}
              >
                <Typography
                  variant="body1"
                  // color='primary'
                  // style={{color: progress >20 ? theme.palette.primary.main : '#444444'}}
                  style={{ color: theme.palette.primary.main, fontFamily: 'Rubik', }}
                >
                  Uploaded at:
                </Typography>
                <Typography
                  variant="body1"
                  // color='primary'
                  // style={{color: progress >20 ? theme.palette.primary.main : '#444444'}}
                  style={{ color: theme.palette.primary.main, fontFamily: 'Rubik', }}
                >
                  {timeStampToDateTime(uploadedVideo.upload_time)}
                </Typography>
              </Box>
            </Box>
          );
        })}
      
    </Box>
  );
};

export default CustomVideoUploads;
