import React, { useMemo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Button, Typography } from "@mui/material";
// import { customTheme as theme } from "../theme/customTheme";
import AddIcon from '@mui/icons-material/Add';
import "@fontsource/rubik";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../redux/commonSlice";
import AgreementPopup from "../pages/events/components/AgreementPopup";
import useCustomTheme from "../theme/useCustomTheme";
import useCustomDropzone from "../hooks/useCustomDropzone";

// const useStyles = makeStyles((theme) => ({
//   center:{
//       display:'flex',
//       alignItems: 'center',
//       justifyContent:'center'
//   }
// }));

// const baseStyle = {
//     // height:'20rem',
//     // width:'30rem',
//     // flex: 1,
//     height:height,
//     width:width,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: '2em',
//     borderWidth: 4,
//     borderRadius: 2,
//     borderColor: '#dddddd',
//     borderStyle: 'dashed',
//     backgroundColor: '#fafafa',
//     color: '#bdbdbd',
//     outline: 'none',
//     transition: 'border .24s ease-in-out'
//   };

// const focusedStyle = {
//   borderColor: "#2196f3",
// };

// const acceptStyle = {
//   borderColor: "#00e676",
// };

// const rejectStyle = {
//   borderColor: "#ff1744",
// };

// const thumbsContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   marginTop: 16
// };

// const thumb = {
//   display: 'inline-flex',
//   // flexDirection:'column',
//   borderRadius: 2,
//   border: '1px solid #eaeaea',
//   marginBottom: 8,
//   marginRight: 8,
//   width: 100,
//   height: 100,
//   padding: 4,
//   boxSizing: 'border-box',
//   alignItems: 'center',
//   overflow: 'hidden',
//   backgroundColor:'#fafafa',
//   background:'linearGradient(#fff, #fff)'
// };

// const thumbInner = {
//   display: 'flex',
//   minWidth: 0,
//   overflow: 'hidden',

// };

// const img = {
//   display: 'block',
//   width: '100%',
//   height: 'auto'
// };

const CustomUploadBox = (props) => {
  const [tempFiles, setTempFiles] = useState([]);
  const dispatch = useDispatch()
  const [theme ] = useCustomTheme()
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const {
    height,
    width,
    setFiles,
    setProgress,
    setUploadStatus,
    isDisabled = true,
    setRequestController,
    eventData
  } = props;

  const { getInputProps, getRootProps, style, open  } = useCustomDropzone(props)
  const browseFilesClickHandler =()=>{
    if(commonReducer.event_details?.consent_accepted == false){
        dispatch(updateState({open_agreement_popup: true}))  
    }else{
        open()     
    }
}

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Grid
        className="container"
        style={{ height: height, width: width }}
        sx={{ display: { xs: "none", md: "inline" }, height:{md:'31rem', lg:'37rem', xl:'41.5rem'} }}
      >
        <Grid {...getRootProps({ style, className: "dropzone" })}>
          <input {...getInputProps()} />
          <CloudUploadOutlinedIcon
            sx={{
              fontSize: { md: "10vw" },
              color: isDisabled ? "#52636C50" : theme.palette.primary.light,
            }}
          />

          <Typography
            style={{
              color:
                isDisabled == true ? "#52636C50" : theme.palette.primary.light,
              fontSize: 24,
              fontWeight: 600,
              textTransform: "uppercase",
              margin: 0,
              padding: 0,
              textAlign: "center",
            }}
          >
            Drag & drop files here
          </Typography>
          <p
            style={{
              color:
                isDisabled == true ? "#52636C50" : theme.palette.primary.light,
              fontWeight: 600,
              fontSize: 20,
              margin: 0,
              padding: 0,
              marginBottom: 10,
            }}
          >
            or
          </p>

          <button
            type="button"
            disabled={isDisabled}
            onClick={browseFilesClickHandler}
            style={{
              cursor: !isDisabled && "pointer",
              color: "white",
              backgroundColor:
                isDisabled == true ? "#52636C50" : theme.palette.primary.dark,
              borderRadius: 20,
              border: "none",
              fontSize: "1.2rem",
              padding: "10px",
              width: "12rem",
              boxShadow: 3,
            }}
            sx={{ boxShadow: 3 }}
          >
            UPLOAD FILES
          </button>
        </Grid>

        {/* <button type='button' onClick={open} style={{color:'white', backgroundColor:'pink', borderRadius:20, border:'none', fontSize:'1rem', padding:'10px', width:'10rem'}}>
          Browse Files
        </button> */}
      </Grid>

      <Box
        sx={{ display: { xs: "flex", md: "none" }, paddingTop: 0, width: '100%'}}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          type="button"
          disabled={isDisabled}
          startIcon ={<AddIcon style={{ fontSize: "48px", fontWeight: 900}} />}
          onClick={browseFilesClickHandler}
          color='secondary'
          variant="contained"
          style={{
            cursor: !isDisabled && "pointer",
            // color: "#08231B",
            // backgroundColor: isDisabled == true ? "#52636C50" : theme.palette.secondary.main,
            borderRadius: "10px",
            border: "none",
            fontSize: "28px",
            fontWeight: 700,
            padding: "10px",
            fontFamily: 'Rubik'
            
          }}
          sx={{ boxShadow: 3, width: '100%', minWidth: "307px", mt: 2, textTransform: 'none' }}
        >
          Upload Videos
        </Button>
      </Box>
      {commonReducer?.open_agreement_popup && <AgreementPopup open={open} eventData={eventData}/>}
    </Box>
  );
};

export default CustomUploadBox;