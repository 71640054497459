// abortManager.js
const abortControllers = new Map();

export const addAbortController = (fileIndex, controller) => {
  abortControllers.set(fileIndex, controller);
};

export const removeAbortController = (fileIndex) => {
  abortControllers.delete(fileIndex);
};

export const abortUpload = (fileIndex) => {
  const controller = abortControllers.get(fileIndex);
  if (controller) {
    controller.abort();
    abortControllers.delete(fileIndex); // Optionally clean up after aborting
  }
};
